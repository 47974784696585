body {
  font-size: .875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}


/*
 * Content
 */

[role="main"] {
  
}

@media (min-width: 768px) {
  [role="main"] {
    
  }
}
