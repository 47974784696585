@import '../../../../node_modules/handsontable-pro/dist/handsontable.full.css';

.handsontable .htDimmed:not(.listbox):not(.htSearchResult){color:#495057; background-color:#eeeeee; }
.handsontable [data-has-revisions=true]{background-color:#47bac1;}

.handsontable .colHeader {
   overflow: hidden;
   /* Expands or shrinks column names as required to fill the available free
   space. Stops column headers overflowing onto filters. Causings Col Header row 
   sizing issues on IE and Edge when enabled. */
   display: flex!important;
   line-height: initial!important;
}