
.sidebar {
  min-width: 240px;
  max-width: 240px;
  /* transition: all .4s ease-in-out */
}

.sidebar,
.sidebar-content {
  background: #354052
}

.sidebar-sticky .sidebar-nav {
  padding-bottom: 0
}

.sidebar-sticky .sidebar-content {
  height: 100vh;
  position: fixed;
  top: 0;
  width: 240px
}

.sidebar-nav {
  padding-bottom: 3.5rem;
  padding-left: 0;
  list-style: none
}

.sidebar-link .feather,
.sidebar-link svg {
  margin-right: .75rem;
  color: #fff
}

.sidebar-link,
a.sidebar-link {
  display: block;
  padding: .75rem 1.25rem;
  color: #ced4da;
  background: #354052;
  transition: background .1s ease-in-out, color .1s ease-in-out;
  position: relative;
  text-decoration: none
}

.sidebar-link:focus {
  outline: 0
}

.sidebar-item.active .sidebar-link:hover,
.sidebar-item.active>.sidebar-link,
.sidebar-link:hover {
  color: #ced4da;
  background: #2d3646
}

.sidebar-dropdown .sidebar-link {
  padding-left: 2.5rem;
  color: #adb5bd;
  background: #313b4c
}

.sidebar-dropdown .sidebar-item.active .sidebar-link,
.sidebar-dropdown .sidebar-link:hover {
  color: #e9ecef;
  background: #2d3646
}

.sidebar [data-toggle=collapse] {
  position: relative
}

.sidebar [data-toggle=collapse]:before {
  content: " ";
  border: solid;
  border-width: 0 .1rem .1rem 0;
  display: inline-block;
  padding: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1.2rem;
  right: 1.25rem;
  transition: all .2s ease-out
}

.sidebar [aria-expanded=true]:before,
.sidebar [data-toggle=collapse]:not(.collapsed):before {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  top: 1.4rem
}

.sidebar-brand {
  font-weight: 500;
  font-size: 1.15rem;
  padding: 1.15rem 1.25rem;
  color: #f8f9fa;
  display: block
}

.sidebar-brand:hover {
  text-decoration: none;
  color: #f8f9fa
}

.sidebar-brand:focus {
  outline: 0
}

.sidebar-brand .feather,
.sidebar-brand svg {
  color: #47bac1;
  height: 24px;
  width: 24px;
  margin-left: -.15rem;
  margin-right: .375rem
}

.sidebar-toggle {
  cursor: pointer;
  width: 26px;
  height: 26px
}

.sidebar.toggled {
  margin-left: -240px
}

@media (min-width:1px) and (max-width:991.98px) {
  .sidebar {
      margin-left: -240px
  }

  .sidebar-collapsed,
  .sidebar.toggled {
      margin-left: 0
  }

  .sidebar-collapsed.toggled {
      margin-left: -220px
  }
}

.sidebar-header {
  background: transparent;
  color: #adb5bd;
  padding: .375rem 1.25rem;
  font-size: .75rem
}

.sidebar-bottom {
  transition: all .4s ease-in-out;
  padding: 1rem;
  width: inherit;
  font-size: .8rem;
  bottom: 0;
  left: 0;
  position: fixed;
  background: #313b4c;
  min-width: 240px;
  max-width: 240px;
  color: #e9ecef;
  margin-left: 0
}

.sidebar.toggled .sidebar-bottom {
  margin-left: -240px
}

.sidebar-badge {
  position: absolute;
  right: 15px;
  top: 14px
}