.navbar {
  border-bottom: 1px solid #e5e9f2
}

@media (max-width:991.98px) {
  .navbar {
      width: 100vw
  }
}

.navbar-brand {
  font-weight: 500
}

.nav-icon {
  padding: .1rem .75rem;
  display: block;
  font-size: 1.5rem;
  color: #6c757d;
  transition: background .1s ease-in-out, color .1s ease-in-out
}

.nav-icon:after {
  display: none !important
}

.nav-icon.active,
.nav-icon:hover {
  color: #47bac1
}

.nav-item .indicator {
  background: #47bac1;
  box-shadow: 0 .1rem .2rem rgba(0, 0, 0, .05);
  border-radius: 50%;
  display: block;
  height: 18px;
  width: 18px;
  padding: 1px;
  position: absolute;
  top: 0;
  right: -8px;
  text-align: center;
  transition: top .1s ease-out;
  font-size: .675rem;
  color: #fff
}

.nav-item:hover .indicator {
  top: -4px
}

.nav-item a:focus {
  outline: 0
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
  .navbar .avatar {
      max-height: 47px
  }
}

@media (max-width:575.98px) {
  .navbar {
      padding: .75rem
  }

  .nav-icon {
      padding: .1rem .75rem
  }

  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
      position: inherit
  }

  .navbar-expand .navbar-nav .dropdown-menu-lg {
      min-width: 100%
  }

  .nav-item .nav-link:after {
      display: none
  }
}

.hamburger,
.hamburger:after,
.hamburger:before {
    cursor: pointer;
    border-radius: 1px;
    height: 3px;
    width: 24px;
    background: #495057;
    display: block;
    content: "";
    transition: background .1s ease-in-out, color .1s ease-in-out
}


.hamburger {
  position: relative
}

.hamburger:before {
  top: -7.5px;
  width: 18px;
  position: absolute
}

.hamburger:after {
  bottom: -7.5px;
  width: 14px;
  position: absolute
}

.sidebar-toggle:hover .hamburger,
.sidebar-toggle:hover .hamburger:after,
.sidebar-toggle:hover .hamburger:before {
  background: #47bac1
}

.hamburger-right,
.hamburger-right:after,
.hamburger-right:before {
  right: 0
}

.dropdown-toggle:after{
  display: inline-block;
}