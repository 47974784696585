footer.footer {
    background: #fff;
    border-top: 1px solid #dee2e6;
    padding: 1rem .75rem
}

footer.footer ul {
    margin-bottom: 0
}

@media (max-width:991.98px) {
    footer.footer {
        width: 100vw
    }
}