:root {
    --blue: #5b7dff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #a180da;
    --red: #f44455;
    --orange: #fd7e14;
    --yellow: #fcc100;
    --green: #5fc27e;
    --teal: #20c997;
    --cyan: #47bac1;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #47bac1;
    --secondary: #a180da;
    --success: #5fc27e;
    --info: #5b7dff;
    --warning: #fcc100;
    --danger: #f44455;
    --light: #f8f9fa;
    --dark: #354052;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --breakpoint-xxl: 1440px;
    --font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}
body,
html {
    height: 100%
}

body {
    overflow-y: scroll
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    html {
        overflow-x: hidden
    }
}

.wrapper {
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -ms-flexbox;
    display: flex;
    width: 100%
}


.content {
    padding: 1.5rem
}

@media (max-width:991.98px) {
    .content {
        width: 100vw;
        max-width: 100vw
    }
}

@media (max-width:1199.98px) {
    .content {
        padding: 1.5rem
    }
}


.main {
    width: 100%;
    min-height: 100vh;
    transition: all .4s ease-in-out
}

@media (max-width:991.98px) {
    .main {
        overflow-x: hidden
    }
}

html[data-useragent*="MSIE 10.0"] .main {
    width: calc(100% - 240px)
}

html[data-useragent*="MSIE 10.0"] .sidebar-toggle {
    display: none !important
}

html[data-useragent*="MSIE 10.0"] .sidebar {
    width: 240px !important;
    min-width: 240px !important;
    margin-left: 0 !important
}

html[data-useragent*="MSIE 10.0"] .toggled.sidebar-collapsed {
    margin-left: 0 !important
}

.btn-primary,
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle,
.btn-outline-primary:hover{color:#fff!important;}


.main{display: flex;flex-direction: column;align-items: stretch;}
main{flex:1;display: flex!important;flex-direction: column;align-items: stretch;}
main .container-fluid{flex:1;display: flex!important;flex-direction: column;align-items: stretch;}
main .container-fluid>.row{flex:1;display: flex!important;flex-direction: column;align-items: stretch;}

.pre-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.loader-cont {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #47bac1;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
.loader-cont:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #a180da;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
.loader-cont:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #5b7dff;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}